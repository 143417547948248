.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  align-items: center;
  justify-content: center;
  background-color: white;
  font-family: 'Lato', sans-serif;
  margin: 0;
  /* overflow-x: hidden; */

  max-width: 1200px; /* or whatever maximum width you desire */
  
  margin-left: auto;
  margin-right: auto;




  /* @media (max-width: 1000px) {
    min-width: 800px;
  }

  @media (max-width: 600px) {
    min-width: 300px;
  } */
}

/* .main-container {
  width: 100%;
} */